<template>
  <!-- <v-snackbar v-model="alert" :color="color" multi-line top>
      {{ text }}
      <v-btn dark text @click="close">
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
  </v-snackbar> -->

<!-- <v-snackbar class="alert alert-warning alert-dismissible fade show" v-model="alert" >
    <button type="button" class="close" data-dismiss="alert" @click="close">&times;</button>
  {{ text }}
</v-snackbar> -->

  <div >
    <!-- <b-alert
      :show="dismissCountDown"
      dismissible
      fade
      :variant="color"
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged" 
      v-model="alert"
    >
      {{ text }}
      <br>
      This alert will dismiss after {{ dismissCountDown }} seconds...
    </b-alert> -->


<div class="modal-dialog modal-dialog-centered">
   {{ text }}
</div>

  </div>

</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'Alert',
    computed: {
      ...mapGetters({
        status : 'alert/status',
        color  : 'alert/color',
        text   : 'alert/text'
      }),
      alert: {
        get () {
          return this.status
        },
        set (value) {
          this.setAlert({
            status : value,
            type  : 'success',
            text  : 'test',
          })
        }
      },
    },
    methods: {
      ...mapActions({
        setAlert  : 'alert/set',
      }),
      close(){
        this.setAlert({
          status : false
        })
      },   
    }
}
</script>

<style scoped>

</style>